import { Component, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { RoutingService } from 'src/app/services/routing.service';
import { SubtitleService } from 'src/app/services/subtitle.service';
import {
  DeleteKojimaProductGQL,
  KojimaProductsGQL,
  RegsiterQueueKojimaGQL,
  UpdateUserKojimaProductGQL,
} from 'src/gql/generated';
import { KojimaProduct, KojimaStock } from 'src/app/models/kojima-product';
import { MessageService } from 'src/app/services/message.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-kojima',
  templateUrl: './kojima.component.html',
  styleUrls: ['./kojima.component.sass'],
})
export class KojimaComponent {
  searchText: string;
  form: UntypedFormGroup;
  isLoading: boolean;

  products: Array<KojimaProduct> = [];
  showZeroStock = false;
  showAnotherPriceModal = false;
  anotherPriceModel = {
    kojimaProduct: null,
    price: null,
  };

  canRegisterNewItems = true;

  selectedArea = [];
  selectedPref = [];
  groupedStores = [];
  selectedStores = [];
  groupedArea = [
    {
      label: 'エリア',
      value: 'area',
      items: [
        {
          label: '北海道・東北',
          value: '北海道・東北',
        },
        {
          label: '栃木・群馬',
          value: '栃木・群馬',
        },
        {
          label: '埼玉・千葉',
          value: '埼玉・千葉',
        },
        {
          label: '東京・神奈川',
          value: '東京・神奈川',
        },
        {
          label: '北陸・甲信越・東海',
          value: '北陸・甲信越・東海',
        },
        {
          label: '関西',
          value: '関西',
        },
        {
          label: '中国地方',
          value: '中国地方',
        },
        {
          label: '九州・沖縄',
          value: '九州・沖縄',
        },
      ],
    },
  ];
  groupedPref = [
    {
      label: '北海道・東北',
      value: '北海道・東北',
      items: [
        {
          label: '北海道',
          value: '北海道',
        },
        {
          label: '青森県',
          value: '青森県',
        },
        {
          label: '岩手県',
          value: '岩手県',
        },
        {
          label: '宮城県',
          value: '宮城県',
        },
        {
          label: '秋田県',
          value: '秋田県',
        },
        {
          label: '山形県',
          value: '山形県',
        },
        {
          label: '福島県',
          value: '福島県',
        },
      ],
    },
    {
      label: '北陸・甲信越・東海',
      value: '北陸・甲信越・東海',
      items: [
        {
          label: '新潟県',
          value: '新潟県',
        },
        {
          label: '山梨県',
          value: '山梨県',
        },
        {
          label: '静岡県',
          value: '静岡県',
        },
        {
          label: '愛知県',
          value: '愛知県',
        },
        {
          label: '三重県',
          value: '三重県',
        },
      ],
    },
    {
      label: '栃木・群馬',
      value: '栃木・群馬',
      items: [
        {
          label: '栃木県',
          value: '栃木県',
        },
        {
          label: '群馬県',
          value: '群馬県',
        },
      ],
    },
    {
      label: '埼玉・千葉',
      value: '埼玉・千葉',
      items: [
        {
          label: '埼玉県',
          value: '埼玉県',
        },
        {
          label: '千葉県',
          value: '千葉県',
        },
      ],
    },
    {
      label: '東京・神奈川',
      value: '東京・神奈川',
      items: [
        {
          label: '東京都',
          value: '東京都',
        },
        {
          label: '神奈川県',
          value: '神奈川県',
        },
      ],
    },
    {
      label: '関西',
      value: '関西',
      items: [
        {
          label: '京都府',
          value: '京都府',
        },
        {
          label: '兵庫県',
          value: '兵庫県',
        },
        {
          label: '大阪府',
          value: '大阪府',
        },
      ],
    },
    {
      label: '中国地方',
      value: '中国地方',
      items: [
        {
          label: '広島県',
          value: '広島県',
        },
        {
          label: '山口県',
          value: '山口県',
        },
      ],
    },
    {
      label: '九州・沖縄',
      value: '九州・沖縄',
      items: [
        {
          label: '福岡県',
          value: '福岡県',
        },
        {
          label: '熊本県',
          value: '熊本県',
        },
        {
          label: '沖縄県',
          value: '沖縄県',
        },
      ],
    },
  ];

  queueItems = [];

  splitStr = '::';
  selectedAreaKey = 'savedKojimaSelectedAreaKey';
  selectedPrefKey = 'savedKojimaSelectedPrefKey';
  selectedStoreKey = 'savedKojimaSelectedStoreKey';

  labelMap = {
    '0000000001': '北海道・東北',
    '0000000002': '栃木・群馬',
    '0000000003': '埼玉・千葉',
    '0000000004': '東京・神奈川',
    '0000000005': '北陸・甲信越・東海',
    '0000000006': '関西',
    '0000000007': '中国地方',
    '0000000009': '九州・沖縄',
  };

  constructor(
    private elementRef: ElementRef,
    private message: MessageService,
    private subtitleService: SubtitleService,
    private routingService: RoutingService,
    private userService: UserService,
    private createGql: RegsiterQueueKojimaGQL,
    private getGql: KojimaProductsGQL,
    private userKojimaUpdateGql: UpdateUserKojimaProductGQL,
    private deleteKojimaProductGQL: DeleteKojimaProductGQL,
  ) {
    this.isLoading = false;
    this.routingService.loginRequired();
    this.userService.kojimaRequired();
    this.subtitleService.setSubTitle('コジマ');
  }

  async ngOnInit() {
    this.selectedArea = JSON.parse(localStorage.getItem(this.selectedAreaKey)) || [];
    this.selectedPref = JSON.parse(localStorage.getItem(this.selectedPrefKey)) || [];
    this.selectedStores = JSON.parse(localStorage.getItem(this.selectedStoreKey)) || [];

    // 何かしらのフィルターがあれば検索する
    if (this.selectedArea.length > 0 || this.selectedPref.length > 0 || this.selectedStores.length > 0) {
      await this.loadProducts();
    }
  }

  async loadProducts() {
    const resp = await firstValueFrom(
      this.getGql.fetch({
        areas: this.selectedArea,
        prefectures: this.selectedPref,
        //stores: this.selectedStores,
      }),
    );

    this.canRegisterNewItems = resp.data.assertKojimaProducts.canRegisterNewItem;

    this.queueItems = resp.data.queueKojimaProducts;
    this.products = [];
    let prefStores = [];
    resp.data.kojimaProducts.forEach((p) => {
      if (p.isDeleted) {
        // 削除済みは非表示
        return;
      }
      let hokkaido: KojimaStock[] = [];
      let tochigi: KojimaStock[] = [];
      let chiba: KojimaStock[] = [];
      let tokyo: KojimaStock[] = [];
      let hokuriku: KojimaStock[] = [];
      let kansai: KojimaStock[] = [];
      let tyugoku: KojimaStock[] = [];
      let kyushu: KojimaStock[] = [];

      let product: KojimaProduct = {
        id: p.id,
        jan: p.product.jan,
        price: p.price,
        name: p.productName,
        lastCheckedAt: p.lastCheckedAt,
        createdAt: p.createdAt,
        hasAnyStock: false,
        hokkaido,
        tochigi,
        chiba,
        tokyo,
        hokuriku,
        kansai,
        tyugoku,
        kyushu,
        all: [],
        userKojimaId: p.userKojimaProduct.id,
        userName: p.userKojimaProduct.user?.name,
        anotherPrice: p.userKojimaProduct.anotherPrice,
      };

      p.stocks.forEach((s) => {
        if (s.hasStock) {
          product.hasAnyStock = true;
        }
        const prefStore = s.prefecture + this.splitStr + s.storeName;
        if (!prefStores.includes(prefStore)) {
          prefStores.push(prefStore);
        }
        const stock: KojimaStock = {
          prefecture: s.prefecture,
          area: s.areaName,
          areaId: s.areaId,
          storeName: s.storeName,
          hasStock: s.hasStock,
          stockValue: s.stockValue,
          note: s.note,
        };
        if (s.areaId === '0000000001') {
          hokkaido.push(stock);
        }
        if (s.areaId === '0000000002') {
          tochigi.push(stock);
        }
        if (s.areaId === '0000000003') {
          chiba.push(stock);
        }
        if (s.areaId === '0000000004') {
          tokyo.push(stock);
        }
        if (s.areaId === '0000000005') {
          hokuriku.push(stock);
        }
        if (s.areaId === '0000000006') {
          kansai.push(stock);
        }
        if (s.areaId === '0000000007') {
          tyugoku.push(stock);
        }
        if (s.areaId === '0000000009') {
          kyushu.push(stock);
        }
      });

      const prefSort = (s1, s2) => (s1.prefecture > s2.prefecture ? 1 : -1);
      hokkaido.sort(prefSort);
      tochigi.sort(prefSort);
      chiba.sort(prefSort);
      tokyo.sort(prefSort);
      hokuriku.sort(prefSort);
      kansai.sort(prefSort);
      tyugoku.sort(prefSort);
      kyushu.sort(prefSort);

      product.all = [...hokkaido, ...tochigi, ...chiba, ...tokyo, ...hokuriku, ...kansai, ...tyugoku, ...kyushu];
      product.filteredStocks = this.doFilters(product.all);

      this.products.push(product);
    });

    this.buildGroupedStores(prefStores);
  }

  // ハードコードしたのでコメントアウト
  //buildPref(
  //  hokkaido: KojimaStock[],
  //  tochigi: KojimaStock[],
  //  chiba: KojimaStock[],
  //  tokyo: KojimaStock[],
  //  hokuriku: KojimaStock[],
  //  kansai: KojimaStock[],
  //  tyugoku: KojimaStock[],
  //  kyushu: KojimaStock[],
  //) {
  //  this.groupedPref = [hokkaido, tochigi, chiba, tokyo, hokuriku, kansai, tyugoku, kyushu].map((stocks) => {
  //    const areaId = stocks.find((s) => !!s.areaId)?.areaId;
  //    const label = !!areaId ? this.labelMap[areaId] : '';
  //    return {
  //      label,
  //      value: label,
  //      items: [
  //        ...new Set(
  //          stocks.map((s) => {
  //            return s.prefecture;
  //          }),
  //        ),
  //      ].map((p) => {
  //        return {
  //          label: p,
  //          value: p,
  //        };
  //      }),
  //    };
  //  });
  //}

  buildGroupedStores(prefStores: string[]) {
    let prefs = [];
    prefStores.forEach((ps) => {
      const pref = ps.split(this.splitStr)[0];
      if (!prefs.includes(pref)) {
        prefs.push(pref);
      }
    });

    this.groupedStores = prefs.map((pref) => {
      const stores = prefStores
        .filter((ps) => ps.startsWith(pref))
        .map((ps) => {
          return ps.split(this.splitStr)[1];
        });
      return {
        label: pref,
        value: pref,
        items: stores.map((s) => {
          return {
            label: pref + ' ' + s,
            value: s,
          };
        }),
      };
    });
  }

  canRegister(): boolean {
    return !!this.searchText && !this.isLoading && this.canRegisterNewItems;
  }

  getStatus(st: boolean): string {
    return st ? '◯' : '×';
  }

  getMyStocks(stocks: KojimaStock[]): string {
    return stocks
      .map((s) => {
        return s.storeName;
      })
      .join('<br/>');
  }

  getRowStyle(product: KojimaProduct) {
    if (!product.hasAnyStock) {
      if (this.showZeroStock) {
        return 'unpublic';
      }
      return 'hide-table-row';
    }
    const isZetoStock = product.filteredStocks.filter((s) => s.hasStock).length == 0;
    if (isZetoStock) {
      if (this.showZeroStock) {
        return 'unpublic';
      }
      return 'hide-table-row';
    }

    return '';
  }

  async doRegister() {
    if (!this.canRegister()) {
      // なぜかdisabledでも押せることがある
      return;
    }
    const searchText = this.searchText;
    if (!searchText) {
      this.message.setFlashMessageWithClear('warn', '入力してください', '');
      return;
    }
    const texts = searchText.split(/\n/, -1);

    let ngTexts = [];
    this.isLoading = true;
    texts.forEach(async (txt) => {
      const splitted = txt.split(/\//, -1);
      let jan;
      if (splitted.length > 1) {
        jan = splitted[splitted.length - 1];
        if (!jan) {
          jan = splitted[splitted.length - 2];
        }
      } else {
        jan = txt;
      }
      if (isNaN(jan)) {
        ngTexts.push(txt);
      } else {
        await firstValueFrom(this.createGql.mutate({ jan: jan }));
      }
    });

    await this.loadProducts();
    if (ngTexts.length > 0) {
      this.message.setFlashMessageWithClear('warn', '登録できなかった商品があります', ngTexts.join('\n'));
    } else {
      this.message.setFlashMessageWithClear('success', '登録しました', '');
    }
    this.searchText = '';
    this.isLoading = false;
  }

  doFilters(stocks: KojimaStock[]) {
    stocks = this.doFilterStocks(stocks);
    //stocks = this.doFilterArea(stocks);
    //stocks = this.doFilterPref(stocks);
    stocks = this.doFilterStores(stocks);
    return stocks;
  }

  private doFilterStocks(stocks: KojimaStock[]) {
    if (stocks.length === 0) {
      return stocks;
    }
    // filter pref and store nmaes
    return stocks.filter((s) => s.hasStock);
  }

  private doFilterArea(stocks: KojimaStock[]) {
    if (this.selectedArea.length === 0 || stocks.length === 0) {
      return stocks;
    }
    // filter pref and store nmaes
    return stocks.filter((s) => {
      return this.selectedArea.includes(s.area);
    });
  }

  private doFilterPref(stocks: KojimaStock[]) {
    if (this.selectedPref.length === 0 || stocks.length === 0) {
      return stocks;
    }
    // filter pref and store nmaes
    return stocks.filter((s) => {
      return this.selectedPref.includes(s.prefecture);
    });
  }

  private doFilterStores(stocks: KojimaStock[]) {
    if (this.selectedStores.length === 0 || stocks.length === 0) {
      return stocks;
    }
    // filter pref and store nmaes
    return stocks.filter((s) => {
      return this.selectedStores.includes(s.storeName);
    });
  }

  doChangeArea($e) {
    localStorage.setItem(this.selectedAreaKey, JSON.stringify(this.selectedArea));
    //this.products.forEach((p) => {
    //  p.filteredStocks = this.doFilters(p.all);
    //});
  }

  doChangePref($e) {
    localStorage.setItem(this.selectedPrefKey, JSON.stringify(this.selectedPref));
    //this.products.forEach((p) => {
    //  p.filteredStocks = this.doFilters(p.all);
    //});
  }

  doChangeStores($e) {
    localStorage.setItem(this.selectedStoreKey, JSON.stringify(this.selectedStores));
    this.products.forEach((p) => {
      p.filteredStocks = this.doFilters(p.all);
    });
  }

  doSearch() {
    this.loadProducts();
  }

  downloadCsv(table: any) {
    const products: KojimaProduct[] = table.filteredValue ?? this.products;
    const values = products.filter((p) => p.filteredStocks.length > 0);
    const csv = values.map((p) => p.jan + ',' + p.price + ',,,' + (p.anotherPrice ?? '')).join('\n');
    console.log(csv);
    this.outputCsv(csv, 'kojima.csv');
  }

  openAnotherPrice(kojimaProduct: KojimaProduct) {
    this.anotherPriceModel.kojimaProduct = kojimaProduct;
    this.showAnotherPriceModal = true;
  }

  async doRegisterAnotherPrice() {
    const id = this.anotherPriceModel.kojimaProduct.userKojimaId;
    const anotherPrice = this.anotherPriceModel.price;
    const result = await firstValueFrom(this.userKojimaUpdateGql.mutate({ id, anotherPrice }));

    if (result.data.updateUserKojimaProduct) {
      this.anotherPriceModel.kojimaProduct.anotherPrice = anotherPrice;
    }

    this.showAnotherPriceModal = false;
    this.anotherPriceModel = {
      kojimaProduct: null,
      price: null,
    };
  }

  async doDelete(product: KojimaProduct) {
    const result = await this.deleteKojimaProductGQL.mutate({ id: product.userKojimaId }).toPromise();
    if (result.data?.deleteKojimaProduct) {
      this.message.setFlashMessageWithClear('success', '削除成功', '削除しました', 3000);
      await this.loadProducts();
    } else {
      this.message.setFlashMessageWithClear(
        'error',
        'エラー',
        '予期せぬエラーが発生しました。もう一度やり直してください。',
        3000,
      );
    }
  }

  private outputCsv(csv: string, filename: string) {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const elem = this.elementRef.nativeElement;
    const link = elem.querySelector('#csv-donwload') as HTMLAnchorElement;
    link.href = url;
    link.download = filename;
    link.click();
  }
}
