import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private flashMessage: any;
  public flashSubject = new Subject<any>();
  public flashState = this.flashSubject.asObservable();

  constructor() {}

  public setFlashMessage(level: string, header: string, message: string) {
    const obj = { severity: level, summary: header, detail: message };
    this.flashMessage = obj;
    this.flashSubject.next(obj);
  }

  public setFlashMessageWithClear(level: string, header: string, message: string, interval: number = 3000) {
    this.setFlashMessage(level, header, message);
    setTimeout(() => {
      this.flashSubject.next(undefined);
    }, interval);
  }
}
