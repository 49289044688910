import { Component, OnInit } from "@angular/core";
import { SubtitleService } from "src/app/services/subtitle.service";
import { UserService } from "src/app/services/user.service";
import { RoutingService } from "src/app/services/routing.service";
import { UserSubRole } from "src/gql/generated";
import { Router } from "@angular/router";
import moment from "moment";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  isStoreManager = false;
  groupName: string;
  hasRefreshToken = true;

  releaseDate = moment("2023-08-28").add(14, "d");

  constructor(
    private router: Router,
    private subtitleService: SubtitleService,
    private userService: UserService,
    private routingService: RoutingService
  ) {
    this.routingService.loginRequired();
    this.subtitleService.setSubTitle("ホーム");
  }

  ngOnInit() {
    this.userService.me((me) => {
      this.isStoreManager = me.isStoreManager;
      this.groupName = me.userGroup.name;
      this.hasRefreshToken = me.hasRefreshToken;
    });
  }

  canShowRakuten(): boolean {
    return this.userService.hasFuncRakuten();
  }

  canShowShare(): boolean {
    return this.userService.hasFuncShare();
  }

  canShowAmazonNotify(): boolean {
    return this.userService.hasFuncAmazonNotify();
  }

  canShowBiccamera(): boolean {
    return this.userService.hasFuncBiccamera();
  }

  canShowKojima(): boolean {
    return this.userService.hasFuncKojima();
  }

  canShowIris(): boolean {
    return this.userService.hasFuncIris();
  }

  canShowSpreadSheet(): boolean {
    return this.userService.hasFuncSpreadSheet();
  }

  canShowSearch(): boolean {
    return (
      this.isStoreManager ||
      this.userService.hasSubRole(UserSubRole.ProductShare)
    );
  }

  goHistory() {
    this.router.navigate(["/changelog"]);
  }

  showNewIcon() {
    return moment().unix() < this.releaseDate.unix();
  }
}
