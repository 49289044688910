<br />
<div
  class="center"
  *ngIf="!hasRefreshToken && canShowShare()">
  <a
    href="https://sellercentral.amazon.co.jp/apps/authorize/consent?application_id=amzn1.sellerapps.app.2061b3cc-fc3b-48cc-8b52-fd333bc47f68&state=link">
    <p-button
      label="まずはここをクリックしてAmazon連携を行ってください"
      styleClass="p-button-danger p-button-lg"></p-button>
  </a>
</div>
<h2 class="center">
  「{{ groupName }}」のらくじゃんへようこそ
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-bell"
    class="p-button-rounded p-button-warning p-button-outlined"
    style="font-size: 2rem"
    severity="danger"
    (click)="goHistory()"></button>
  <p-badge
    *ngIf="showNewIcon()"
    value="New"
    severity="danger"
    size="null"></p-badge>
</h2>

<div class="grid">
  <div class="col-1 lg:col-3 xl:col-3"></div>

  <div class="col-5 lg:col-3 xl:col-3">
    <p-card
      class="clickable"
      routerLink="/products/search"
      *ngIf="canShowRakuten()">
      <div class="center">
        <i class="pi pi-shopping-cart icon-big"></i>
      </div>
      <h2 class="center">商品検索</h2>
    </p-card>
  </div>

  <div class="col-5 lg:col-3 xl:col-3">
    <p-card
      class="clickable"
      routerLink="/share"
      *ngIf="canShowShare()">
      <div class="center">
        <i class="pi pi-share-alt icon-big"></i>
      </div>
      <h2 class="center">商品共有</h2>
    </p-card>
  </div>

  <div class="col-1 lg:col-3 xl:col-3"></div>

  <div class="col-1 lg:col-3 xl:col-3"></div>

  <div class="col-5 lg:col-3 xl:col-3">
    <p-card
      class="clickable"
      routerLink="/amazon-notification"
      *ngIf="canShowAmazonNotify()">
      <div class="center">
        <i class="pi pi-amazon icon-big"></i>
      </div>
      <h2 class="center">Amazon通知</h2>
    </p-card>
  </div>

  <div class="col-5 lg:col-3 xl:col-3">
    <p-card
      class="clickable"
      styleClass="p-primary"
      routerLink="/biccamera"
      *ngIf="canShowBiccamera()">
      <div class="center">
        <i class="pi pi-camera icon-big"></i>
      </div>
      <h2 class="center">ビックカメラ</h2>
    </p-card>
  </div>

  <div class="col-1 lg:col-3 xl:col-3"></div>

  <div class="col-1 lg:col-3 xl:col-3"></div>

  <div class="col-5 lg:col-3 xl:col-3">
    <p-card
      class="clickable"
      styleClass="p-primary"
      routerLink="/kojima"
      *ngIf="canShowKojima()">
      <div class="center">
        <i class="pi pi-sun icon-big"></i>
      </div>
      <h2 class="center">コジマ</h2>
    </p-card>
  </div>

  <div class="col-5 lg:col-3 xl:col-3">
    <p-card
      class="clickable"
      styleClass="p-primary"
      routerLink="/iris"
      *ngIf="canShowIris()">
      <div class="center">
        <i class="pi pi-pencil icon-big"></i>
      </div>
      <h2 class="center">アイリス</h2>
    </p-card>
  </div>

  <div class="col-1 lg:col-3 xl:col-3"></div>

  <div class="col-1 lg:col-3 xl:col-3"></div>

  <div class="col-5 lg:col-3 xl:col-3">
    <p-card
      class="clickable"
      styleClass="p-primary"
      routerLink="/yamada"
      *ngIf="canShowSpreadSheet()">
      <div class="center">
        <i class="pi pi-caret-up icon-big"></i>
      </div>
      <h2 class="center">ヤマダ</h2>
    </p-card>
  </div>

  <div class="col-5 lg:col-3 xl:col-3">
    <p-card
      class="clickable"
      styleClass="p-primary"
      routerLink="/edion"
      *ngIf="canShowSpreadSheet()">
      <div class="center">
        <i class="pi pi-circle-off icon-big"></i>
      </div>
      <h2 class="center">エディオン</h2>
    </p-card>
  </div>

  <div class="col-1 lg:col-3 xl:col-3"></div>

  <div class="col-1 lg:col-3 xl:col-3"></div>

  <div class="col-5 lg:col-3 xl:col-3">
    <p-card
      class="clickable"
      styleClass="p-primary"
      routerLink="/donki"
      *ngIf="canShowSpreadSheet()">
      <div class="center">
        <i class="pi pi-star icon-big"></i>
      </div>
      <h2 class="center">ドンキ</h2>
    </p-card>
  </div>

  <!--
  <div class="col-3" *ngIf="isStoreManager">
    <p-card
      class="clickable"
      styleClass="p-primary"
      routerLink="/price-tracker"
    >
      <div class="center">
        <i class="pi pi-bell icon-big"></i>
      </div>
      <h2 class="center">Amazon価格追跡</h2>
    </p-card>
  </div>
  -->

  <!--
  <div class="col-3"></div>
  <div class="col-3">
    <p-card class="clickable" routerLink="/products/manage" *ngIf="isAdmin">
      <div class="center">
        <i class="pi pi-list icon-big"></i>
      </div>
      <h2 class="center">
        商品管理
      </h2>
    </p-card>
  </div>
  <div class="col-3"></div>
  -->
</div>
