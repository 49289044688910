import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';

import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PasswordModule } from 'primeng/password';
import { PanelModule } from 'primeng/panel';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FileUploadModule } from 'primeng/fileupload';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DragDropModule } from 'primeng/dragdrop';
import { BadgeModule } from 'primeng/badge';
import { ChipModule } from 'primeng/chip';
import { TagModule } from 'primeng/tag';
import { TimelineModule } from 'primeng/timeline';
import { SliderModule } from 'primeng/slider';
import { DividerModule } from 'primeng/divider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ToastModule } from 'primeng/toast';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { SearchComponent } from './components/products/search/search.component';
import { FlashMessageComponent } from './components/parts/flash-message/flash-message.component';
import { httpInterceptorProviders } from './interceptors/interceptor.module';
import { JapanDatePipe } from './pipe/japan-date.pipe';
import { SentryErrorHandler } from './components/sentry/sentry-error-handler.component';
import { CheckComponent } from './components/register/check/check.component';
import { GraphQLModule } from './modules/graphql.module';
import { ProductShareComponent } from './components/product-share/product-share.component';
import { RegisterComponent } from './components/product-share/register/register.component';
import { StoreComponent } from './components/product-share/store/store.component';
import { PriceTrackerComponent } from './components/price-tracker/price-tracker.component';
import { BiccameraComponent } from './components/biccamera/biccamera.component';
import { BiccameraStockComponent } from './components/biccamera/biccamera-stock/biccamera-stock.component';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { TooltipModule } from 'primeng/tooltip';
import { IrisComponent } from './components/iris/iris.component';
import { CallbackComponent } from './components/oauth/callback/callback.component';
import { KojimaComponent } from './components/kojima/kojima.component';
import { AmazonNoficationComponent } from './components/amazon-notification/amazon-notification.component';
import { BadgesComponent } from './components/amazon-notification/badges/badges.component';
import { YamadaComponent } from './components/yamada/yamada.component';
import { EdionComponent } from './components/edion/edion.component';
import { DonkiComponent } from './components/donki/donki.component';
import { SpreadsheetStockBadgeComponent } from './components/spreadsheet-stock-badge/spreadsheet-stock-badge.component';

import { ConfirmationService, MessageService } from 'primeng/api';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SearchComponent,
    FlashMessageComponent,
    JapanDatePipe,
    CheckComponent,
    ProductShareComponent,
    RegisterComponent,
    StoreComponent,
    PriceTrackerComponent,
    BiccameraComponent,
    BiccameraStockComponent,
    ChangelogComponent,
    IrisComponent,
    CallbackComponent,
    KojimaComponent,
    AmazonNoficationComponent,
    BadgesComponent,
    YamadaComponent,
    EdionComponent,
    DonkiComponent,
    SpreadsheetStockBadgeComponent,
  ],
  imports: [
    GraphQLModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    ButtonModule,
    SplitButtonModule,
    InputTextModule,
    InputTextareaModule,
    PasswordModule,
    PanelModule,
    MenubarModule,
    MessagesModule,
    MessageModule,
    CheckboxModule,
    CardModule,
    RadioButtonModule,
    SelectButtonModule,
    TableModule,
    DropdownModule,
    DialogModule,
    OverlayPanelModule,
    ProgressBarModule,
    CalendarModule,
    FileUploadModule,
    TabViewModule,
    AccordionModule,
    FieldsetModule,
    InputSwitchModule,
    DragDropModule,
    BadgeModule,
    ChipModule,
    TagModule,
    TimelineModule,
    TooltipModule,
    SliderModule,
    DividerModule,
    MultiSelectModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ToastModule,
  ],
  providers: [
    httpInterceptorProviders,
    ConfirmationService,
    MessageService,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
