import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RoutingService } from 'src/app/services/routing.service';
import { SubtitleService } from 'src/app/services/subtitle.service';
import { DateService } from 'src/app/services/date.service';
import { Router } from '@angular/router';
import { UserPricetarService } from 'src/app/services/user-pricetar.service';
import { UserSedoristService } from 'src/app/services/user-sedorist.service';
import {
  AmazonCategoriesQuery,
  FilterOtherSetting,
  FilterUserProduct,
  UpdateUserPricetarProductMutationVariables,
  UpdateUserSedoristProductMutationVariables,
  UserPricetarProductsQueryVariables,
  UserSedoristProductsQueryVariables,
} from 'src/gql/generated';
import { MessageService } from 'src/app/services/message.service';
import { BuyingStoreService } from 'src/app/services/buying-store.service';
import moment from 'moment';
import { UserService } from 'src/app/services/user.service';
import { AmazonCategoryService } from 'src/app/services/amazon-category.service';

@Component({
  selector: 'app-product-share',
  templateUrl: './product-share.component.html',
  styleUrls: ['./product-share.component.sass'],
})
export class ProductShareComponent implements OnInit {
  form: UntypedFormGroup;
  param: ProductShareFilterParam;
  sharedOptions = [
    { name: '全ての商品', value: FilterUserProduct.All },
    { name: '自分の商品', value: FilterUserProduct.My },
    { name: '店舗未設定商品', value: FilterUserProduct.Nonestore },
  ];
  otherSettingOptions = [
    { name: '寝かせのみ', value: FilterOtherSetting.IsSleeped },
    {
      name: 'レジ落ちのみ',
      value: FilterOtherSetting.IsPriceDownInCacheRegister,
    },
    { name: 'メモありのみ', value: FilterOtherSetting.HasMemo },
    { name: '知財・真贋ありのみ', value: FilterOtherSetting.HasIpMemo },
  ];

  pageSizes = [{ size: 30 }, { size: 50 }, { size: 100 }];
  selectedPageSize = { size: 50 };

  sharedOptionVal = this.sharedOptions[0];
  otherSettingVal;
  buyingStoreVal;
  buyingStoreTypeVal;
  users;
  userVal;
  showColsVal = true;
  isHiddenUnPublic = false;
  pricetars: Array<Pricetar>;
  sedorists: Array<Sedorist>;
  mergedList: Array<Merged>;

  types: Array<BuyingStoreType> = [];
  buyingStores: Array<BuyingStore>;
  lastBuyingStore: BuyingStore;
  amazonCategories: Array<AmazonCategory>;
  amazonCategoryVal: string;
  selectedBuyingStore: BuyingStore;
  newBuyingStoreName: string;
  isAdmin: boolean;

  selectedColumnId: number; // sedorist / pricetarの店舗設定時に選択したID
  selectedFromMeged: boolean;
  selectedMerged: Merged; // mergedの店舗設定時に選択したobject

  showPricetarModal = false;
  showSedoristModal = false;

  // modal setting
  settingPublic: boolean;
  settingIsPriceDownInCacheRegister: boolean;
  settingIsSleeped: boolean;
  settingMemo: string;
  settingIpMemo: string;

  // kojima / bic filter
  isKojimaBicOnly = false;
  kojimaBicStoreIds = [];
  bicName = 'ビックカメラ';
  kojimaName = 'コジマ';
  kojimaBicTypeName = '家電量販店';

  constructor(
    private router: Router,
    private builer: UntypedFormBuilder,
    private elementRef: ElementRef,
    private routingService: RoutingService,
    private subtitleService: SubtitleService,
    private userService: UserService,
    private dateService: DateService,
    private messageService: MessageService,
    private pricetarService: UserPricetarService,
    private sedoristService: UserSedoristService,
    private buyingStoreService: BuyingStoreService,
    private amazonCategoryService: AmazonCategoryService,
  ) {
    this.routingService.loginRequired();
    this.userService.shareRequired();
    this.isKojimaBicOnly = this.userService.hasShareKojimaBicOnly();
    this.subtitleService.setSubTitle('商品共有');
  }

  ngOnInit(): void {
    this.initForm();
    this.loadUsers();
    this.loadBuyingStore();
    this.loadMe();
    this.loadAmazonCategory();
  }

  initForm() {
    const fromYear = this.dateService.getAddedDateTrancated(-30).getFullYear();
    this.param = {
      fromDate: moment().startOf('month').toDate(),
      toDate: this.dateService.getAddedDateTrancated(1),
    } as ProductShareFilterParam;
    this.form = this.builer.group({
      keyword: [this.param.keyword, []],
      fromDate: [this.param.fromDate, []],
      toDate: [this.param.toDate, []],
      sharedOption: [this.sharedOptionVal, []],
      otherSetting: [this.otherSettingVal, []],
      buyingStore: [this.buyingStoreVal, []],
      buyingStoreType: [this.buyingStoreTypeVal, []],
      amazonCategory: [this.amazonCategoryVal, []],
      user: [this.userVal, []],
    });
  }

  loadAmazonCategory() {
    this.amazonCategoryService.list(true, (resp) => {
      this.amazonCategories = resp.amazonCategories.map((c) => {
        return {
          id: c.id,
          name: c.name,
        };
      });
    });
  }

  loadMe() {
    this.userService.me((resp) => {
      this.isAdmin = resp.userRole === 'admin';
    });
  }

  goBack() {
    this.router.navigate(['/']);
  }

  goRegister() {
    this.router.navigate(['/share/register']);
  }

  goStoreManage() {
    this.router.navigate(['/share/store']);
  }

  clear() {
    this.sedorists = [];
    this.pricetars = [];
    this.mergedList = [];
  }

  changeType() {
    const type = this.form.value.buyingStoreType;
    if (type) {
      this.buyingStores = type.stores;
    } else {
      this.loadBuyingStore();
    }
  }

  async loadUsers() {
    this.users = [];

    await this.userService.getAllUsers((resp) => {
      const allUsers = resp.users?.filter((u) => !!u.name);
      const firstUsers = allUsers.filter((u) => u.name == '武田（管理人）' || u.name == '管理人');
      const otherUsers = allUsers.filter((u) => u.name != '武田（管理人）' && u.name != '管理人');
      this.users = firstUsers.concat(otherUsers);
    });
  }

  loadBuyingStore() {
    this.types = [];
    this.buyingStores = [];

    this.buyingStoreService.fetchTypes((types) => {
      types.buyingStoreTypes.forEach((t) => {
        if (this.isKojimaBicOnly && t.name != this.kojimaBicTypeName) {
          return;
        }
        const stores = [];
        t.buyingStores.forEach((s) => {
          if (this.isKojimaBicOnly && s.name != this.bicName && s.name != this.kojimaName) {
            return;
          }
          stores.push({
            id: s.id,
            name: s.name,
          });
          this.buyingStores.push({
            id: s.id,
            name: t.name + ' - ' + s.name,
          });
          if (this.isKojimaBicOnly) {
            this.kojimaBicStoreIds.push(s.id);
          }
        });
        this.types.push({
          id: t.id,
          name: t.name,
          stores,
        });
      });
    });
  }

  hiddenCols(e) {
    this.showColsVal = !e.checked;
  }

  hiddenUnPublic(e) {
    this.isHiddenUnPublic = e.checked;
  }

  async doSearchPricetar() {
    this.clear();
    this.pricetars = await this.searchPricetar();
  }

  private async searchPricetar(): Promise<Pricetar[]> {
    const v = this.form.value;
    const buyingStoreIds = v.buyingStore
      ? [v.buyingStore.id]
      : this.isKojimaBicOnly
        ? this.kojimaBicStoreIds
        : undefined;
    const buyingStoreTypeId = v.buyingStoreType ? v.buyingStoreType.id : undefined;
    const keyword = v.keyword;
    const filterUserProduct = v.sharedOption.value;
    const fromDate = moment(v.fromDate).format('YYYY-MM-DD');
    const toDate = moment(v.toDate).format('YYYY-MM-DD');
    const filterUserIds = v.user?.map((u) => u.id);
    const amazonCategoryId = v.amazonCategory?.id;
    const filterOtherSetting = v.otherSetting?.value;

    const body: UserPricetarProductsQueryVariables = {
      keyword,
      fromDate,
      toDate,
      filterUserProduct,
      filterOtherSetting,
      buyingStoreIds,
      buyingStoreTypeId,
      filterUserIds,
      amazonCategoryId,
    };
    const result = [];
    await this.pricetarService.list(
      body,
      (data) => {
        data.userPricetarProducts.forEach((p) => {
          if (this.isHiddenUnPublic && !p.isPublic) {
            return;
          }
          const buyingStore = p.buyingStore && p.buyingStore.name;
          const profit = p.sellingPrice - p.buyingPrice - p.salesCommission;
          const profitRate = profit / p.sellingPrice;
          result.push({
            id: p.id,
            asin: p.amazonProduct.asin,
            jan: p.product?.jan,
            imageUrl: p.amazonProduct.imageUrl,
            sku: p.sku,
            email: p.user.email,
            name: p.user.name,
            productName: p.productName,
            buyingPrice: p.buyingPrice,
            sellingPrice: p.sellingPrice,
            orderDate: new Date(p.orderDate),
            buyingStore,
            salesCommission: p.salesCommission,
            profit,
            profitRate,
            deliveryRoute: p.deliveryRoute,
            memo: p.memo,
            ipMemo: p.ipMemo,
            isPriceDownInCacheRegister: p.isPriceDownInCacheRegister,
            isSleeped: p.isSleeped,
            condition: p.condition,
            topCategory: p.topCategory,
            isPublic: p.isPublic,
            createdAt: new Date(p.createdAt),
          });
        });
      },
      (err) => {
        console.log(err);
      },
    );
    return result;
  }

  doDeletePricetar(pricetarId: number) {
    this.pricetarService.delete({ id: pricetarId }, (resp) => {
      if (this.selectedFromMeged) {
        this.mergedList = this.mergedList.filter((p) => p.pricetarId !== pricetarId);
      } else {
        this.pricetars = this.pricetars.filter((p) => p.id !== pricetarId);
      }
      this.messageService.setFlashMessageWithClear('success', '削除成功', '削除しました', 3000);
      this.showPricetarModal = false;
    });
  }

  doDeleteSedorist(sedoristId: number) {
    this.sedoristService.delete({ id: sedoristId }, (resp) => {
      if (this.selectedFromMeged) {
        this.mergedList = this.mergedList.filter((p) => p.sedoristId !== sedoristId);
      } else {
        this.sedorists = this.sedorists.filter((p) => p.id !== sedoristId);
      }
      this.messageService.setFlashMessageWithClear('success', '削除成功', '削除しました', 3000);
      this.showSedoristModal = false;
    });
  }

  doDeleteMerged(merged: Merged) {
    if (merged.serviceName === 'S') {
      this.doDeleteSedorist(merged.sedoristId);
      this.mergedList = this.mergedList.filter((p) => p.sedoristId !== merged.sedoristId);
    } else {
      // pricetar
      this.doDeletePricetar(merged.pricetarId);
      this.mergedList = this.mergedList.filter((p) => p.pricetarId !== merged.pricetarId);
    }
  }

  canSetting(email: string): boolean {
    return this.userService.getEmail() === email || this.userService.isAdmin();
  }

  canEdit(email: string): boolean {
    return this.canSetting(email);
  }

  hasLastStore(): boolean {
    return this.lastBuyingStore != null;
  }

  toEditable(sedorist: Sedorist) {
    sedorist.isEditing = true;
  }

  hasPricetar() {
    return this.pricetars && this.pricetars.length > 0;
  }

  hasSedorist() {
    return this.sedorists && this.sedorists.length > 0;
  }

  hasMerged() {
    return this.mergedList && this.mergedList.length > 0;
  }

  getProfitStyle(profit: number): string {
    if (profit < 0) {
      return 'color: red';
    }
    return 'color: blue';
  }

  updateSedoristStore(op) {
    this._updateSedoristStore(() => {
      op.hide();
    }, true);
  }

  updateSameSedoristStore(buyingStore, sedoristId) {
    this.selectedBuyingStore = buyingStore;
    this.selectedColumnId = sedoristId;
    this._updateSedoristStore(() => {}, true);
  }

  _updateSedoristStore(cb, doExecUpdateSelf: boolean) {
    if (!!!this.selectedBuyingStore || !!!this.selectedColumnId) {
      return;
    }
    const body = {
      id: this.selectedColumnId,
      storeId: this.selectedBuyingStore.id,
    };
    this.sedoristService.updateSettings(body, (resp) => {
      if (doExecUpdateSelf) {
        const s = this.sedorists.find((sedo) => sedo.id === this.selectedColumnId);
        s.buyingStore = this.selectedBuyingStore.name;
      }
      this.selectedBuyingStore = undefined;
      this.selectedColumnId = undefined;
      this.selectedMerged = undefined;
      cb();
    });
    this.lastBuyingStore = this.selectedBuyingStore;
  }

  updatePricetarStore(op) {
    this._updatePricetarStore(() => {
      op.hide();
    }, true);
  }

  updateSamePricetarStore(buyingStore, pricetarId) {
    this.selectedBuyingStore = buyingStore;
    this.selectedColumnId = pricetarId;
    this._updatePricetarStore(() => {}, true);
  }

  _updatePricetarStore(cb, doExecUpdateSelf: boolean) {
    if (!!!this.selectedBuyingStore || !!!this.selectedColumnId) {
      return;
    }
    const body = {
      id: this.selectedColumnId,
      storeId: this.selectedBuyingStore.id,
    };
    this.pricetarService.updateSettings(body, (resp) => {
      if (doExecUpdateSelf) {
        const s = this.pricetars.find((sedo) => sedo.id === this.selectedColumnId);
        s.buyingStore = this.selectedBuyingStore?.name;
      }
      this.selectedBuyingStore = undefined;
      this.selectedColumnId = undefined;
      this.selectedMerged = undefined;
      cb();
    });
    this.lastBuyingStore = this.selectedBuyingStore;
  }

  updateMergedStore(op) {
    this._updateMergedStore(() => {
      op.hide();
    });
  }

  updateSameMergedStore(buyingStore, merged: Merged) {
    this.selectedBuyingStore = buyingStore;
    this.selectedMerged = merged;
    this._updateMergedStore(() => {});
  }

  _updateMergedStore(cb) {
    if (!!!this.selectedMerged) {
      return;
    }
    if (this.selectedMerged.serviceName === 'S') {
      this.selectedColumnId = this.selectedMerged.sedoristId;
      this._updateSedoristStore(() => {
        cb();
      }, false);
    } else {
      this.selectedColumnId = this.selectedMerged.pricetarId;
      this._updatePricetarStore(() => {
        cb();
      }, false);
    }
    this.selectedMerged.buyingStore = this.selectedBuyingStore.name;
  }

  openIdModal(op, ev, id) {
    op.toggle(ev);
    this.selectedColumnId = id;
  }

  openStoreMerged(op, ev, merged: Merged) {
    op.toggle(ev);
    this.selectedMerged = merged;
  }

  openPricetarModal(pricetar: Pricetar) {
    this.selectedColumnId = pricetar.id;
    this.settingPublic = pricetar.isPublic;
    this.settingIsPriceDownInCacheRegister = pricetar.isPriceDownInCacheRegister;
    this.settingIsSleeped = pricetar.isSleeped;
    this.settingMemo = pricetar.memo;
    this.settingIpMemo = pricetar.ipMemo;
    this.selectedFromMeged = false;

    this.showPricetarModal = true;
  }

  openSedoristModal(sedorist: Sedorist) {
    this.selectedColumnId = sedorist.id;
    this.settingPublic = sedorist.isPublic;
    this.settingIsPriceDownInCacheRegister = sedorist.isPriceDownInCacheRegister;
    this.settingIsSleeped = sedorist.isSleeped;
    this.settingMemo = sedorist.memo;
    this.settingIpMemo = sedorist.ipMemo;
    this.selectedFromMeged = false;

    this.showSedoristModal = true;
  }

  openMergedModal(merged: Merged) {
    this.settingPublic = merged.isPublic;
    this.settingIsPriceDownInCacheRegister = merged.isPriceDownInCacheRegister;
    this.settingIsSleeped = merged.isSleeped;
    this.settingIpMemo = merged.ipMemo;
    this.selectedFromMeged = true;

    if (merged.serviceName === 'S') {
      this.selectedColumnId = merged.sedoristId;
      this.showSedoristModal = true;
    } else {
      this.selectedColumnId = merged.pricetarId;
      this.showPricetarModal = true;
    }
  }

  setSettingIsPublic(ev) {
    this.settingPublic = ev.checked;
  }

  setSettingIsSleeped(ev) {
    this.settingIsSleeped = ev.checked;
  }

  setSettingIsPriceDownInCacheRegister(ev) {
    this.settingIsPriceDownInCacheRegister = ev.checked;
  }

  updatePricetarSetting() {
    this._updatePricetar(
      this.settingMemo,
      this.settingIpMemo,
      this.settingIsPriceDownInCacheRegister,
      this.settingIsSleeped,
      this.settingPublic,
      () => {
        this.showPricetarModal = false;
      },
    );
  }

  updateSedoristSetting() {
    this._updateSedorist(
      this.settingMemo,
      this.settingIpMemo,
      this.settingIsPriceDownInCacheRegister,
      this.settingIsSleeped,
      this.settingPublic,
      () => {
        this.showSedoristModal = false;
      },
    );
  }

  async doSearchSedorist() {
    this.clear();
    this.sedorists = await this.searchSedorist();
  }

  _updatePricetar(
    memo?: string,
    ipMemo?: string,
    isPriceDownInCacheRegister?: boolean,
    isSleeped?: boolean,
    isPublic?: boolean,
    cb?: any,
  ) {
    const body: UpdateUserPricetarProductMutationVariables = {
      id: this.selectedColumnId,
      memo,
      ipMemo,
      isPriceDownInCacheRegister,
      isSleeped,
      isPublic,
    };
    this.pricetarService.updateSettings(body, (resp) => {
      var s: Pricetar | Merged;
      if (this.selectedFromMeged) {
        s = this.mergedList.find((sedo) => sedo.pricetarId === this.selectedColumnId);
      } else {
        s = this.pricetars.find((sedo) => sedo.id === this.selectedColumnId);
      }
      s.memo = memo;
      s.ipMemo = ipMemo;
      s.isPriceDownInCacheRegister = isPriceDownInCacheRegister;
      s.isSleeped = isSleeped;
      s.isPublic = isPublic;

      this.selectedColumnId = undefined;
      this.selectedFromMeged = false;
      this.selectedMerged = undefined;
      if (cb) {
        cb();
      }
    });
  }

  _updateSedorist(
    memo?: string,
    ipMemo?: string,
    isPriceDownInCacheRegister?: boolean,
    isSleeped?: boolean,
    isPublic?: boolean,
    cb?: any,
  ) {
    const body: UpdateUserSedoristProductMutationVariables = {
      id: this.selectedColumnId,
      memo,
      ipMemo,
      isPriceDownInCacheRegister,
      isSleeped,
      isPublic,
    };
    this.sedoristService.updateSettings(body, (resp) => {
      var s: Sedorist | Merged;
      if (this.selectedFromMeged) {
        s = this.mergedList.find((sedo) => sedo.sedoristId === this.selectedColumnId);
      } else {
        s = this.sedorists.find((sedo) => sedo.id === this.selectedColumnId);
      }

      s.memo = memo;
      s.ipMemo = ipMemo;
      s.isPriceDownInCacheRegister = isPriceDownInCacheRegister;
      s.isSleeped = isSleeped;
      s.isPublic = isPublic;

      this.selectedColumnId = undefined;
      this.selectedFromMeged = false;
      this.selectedMerged = undefined;
      if (cb) {
        cb();
      }
    });
  }

  updateMerged(
    merged: Merged,
    memo?: string,
    ipMemo?: string,
    isPriceDownInCacheRegister?: boolean,
    isSleeped?: boolean,
    isPublic?: boolean,
  ) {
    merged.isPublic = isPublic;
    merged.memo = memo;
    merged.isPriceDownInCacheRegister = isPriceDownInCacheRegister;
    merged.isSleeped = isSleeped;

    if (merged.serviceName === 'S') {
      this._updateSedorist(memo, ipMemo, isPriceDownInCacheRegister, isSleeped, isPublic);
    } else {
      // pricetar
      this._updatePricetar(memo, ipMemo, isPriceDownInCacheRegister, isSleeped, isPublic);
    }
  }

  getRowStyle(isPublic: boolean) {
    if (!isPublic) {
      return 'unpublic';
    }
    return '';
  }

  private async searchSedorist(): Promise<Sedorist[]> {
    const v = this.form.value;
    const buyingStoreIds = v.buyingStore
      ? [v.buyingStore.id]
      : this.isKojimaBicOnly
        ? this.kojimaBicStoreIds
        : undefined;
    const buyingStoreTypeId = v.buyingStoreType ? v.buyingStoreType.id : undefined;
    const keyword = v.keyword;
    const filterUserProduct = v.sharedOption.value;
    const fromDate = moment(v.fromDate).format('YYYY-MM-DD');
    const toDate = moment(v.toDate).format('YYYY-MM-DD');
    const filterUserIds = v.user?.map((u) => u.id);
    const filterOtherSetting = v.otherSetting?.value;
    const amazonCategoryId = v.amazonCategory?.id;
    const body: UserSedoristProductsQueryVariables = {
      keyword,
      fromDate,
      toDate,
      filterUserProduct,
      filterOtherSetting,
      buyingStoreIds,
      buyingStoreTypeId,
      filterUserIds,
      amazonCategoryId,
    };
    const result = [];

    await this.sedoristService.list(body, (data) => {
      data.userSedoristProducts.forEach((p) => {
        let jan = '';
        if (p.product) {
          jan = p.product.jan;
        }
        const buyingStore = p.buyingStore && p.buyingStore.name;
        let commission = 0;
        if (p.amazonProduct.salesCommission) {
          commission += p.amazonProduct.salesCommission;
        }
        if (p.amazonProduct.categoryFee) {
          commission += p.amazonProduct.categoryFee;
        }
        if (p.amazonProduct.fbaShippingFee) {
          commission += p.amazonProduct.fbaShippingFee;
        }
        const profit = p.sellingPrice - p.buyingPrice - commission;
        const profitRate = profit / p.sellingPrice;
        result.push({
          id: p.id,
          asin: p.amazonProduct.asin,
          imageUrl: p.amazonProduct.imageUrl,
          jan,
          sku: p.sku,
          email: p.user.email,
          name: p.user.name,
          productName: p.productName,
          buyingPrice: p.buyingPrice,
          sellingPrice: p.sellingPrice,
          profit,
          profitRate,
          buyingDate: new Date(p.buyingDate),
          buyingStore,
          isEditing: false,
          condition: p.condition,
          topCategory: p.topCategory,
          deliveryRoute: p.deliveryRoute,
          memo: p.memo,
          ipMemo: p.ipMemo,
          isPriceDownInCacheRegister: p.isPriceDownInCacheRegister,
          isSleeped: p.isSleeped,
          isPublic: p.isPublic,
          createdAt: new Date(p.createdAt),
        });
      });
    });
    return result;
  }

  async doSearchMerged() {
    this.clear();
    const pricetars = await this.searchPricetar();
    const sedorists = await this.searchSedorist();

    this.mergedList = [];
    pricetars.forEach((p) => {
      this.mergedList.push({
        sedoristId: undefined,
        pricetarId: p.id,
        asin: p.asin,
        imageUrl: p.imageUrl,
        jan: null,
        sku: p.sku,
        email: p.email,
        name: p.name,
        productName: p.productName,
        buyingPrice: p.buyingPrice,
        sellingPrice: p.sellingPrice,
        profit: p.profit,
        profitRate: p.profitRate,
        buyingDate: p.orderDate,
        buyingStore: p.buyingStore,
        condition: p.condition,
        deliveryRoute: p.deliveryRoute,
        memo: p.memo,
        ipMemo: p.ipMemo,
        isPriceDownInCacheRegister: p.isPriceDownInCacheRegister,
        isSleeped: p.isSleeped,
        topCategory: p.topCategory,
        isPublic: p.isPublic,
        serviceName: 'P',
        createdAt: p.createdAt,
      });
    });

    sedorists.forEach((p) => {
      this.mergedList.push({
        sedoristId: p.id,
        pricetarId: undefined,
        asin: p.asin,
        imageUrl: p.imageUrl,
        jan: p.jan,
        sku: p.sku,
        email: p.email,
        name: p.name,
        productName: p.productName,
        buyingPrice: p.buyingPrice,
        sellingPrice: p.sellingPrice,
        profit: p.profit,
        profitRate: p.profitRate,
        buyingDate: p.buyingDate,
        buyingStore: p.buyingStore,
        condition: p.condition,
        deliveryRoute: p.deliveryRoute,
        memo: p.memo,
        ipMemo: p.ipMemo,
        isPriceDownInCacheRegister: p.isPriceDownInCacheRegister,
        isSleeped: p.isSleeped,
        topCategory: p.topCategory,
        isPublic: p.isPublic,
        serviceName: 'S',
        createdAt: p.createdAt,
      });
    });
  }

  outputPricetarCsv() {
    const csv =
      'ASIN,JAN,仕入値,在庫数,販売価格,購入店舗\n' +
      this.pricetars
        .map((p) => {
          return [p.asin, p.jan, p.buyingPrice, 1, p.sellingPrice, p.buyingStore].join(',');
        })
        .join('\n');

    this.outputCsv(csv, 'pricetar.csv');
  }

  outputAllPricetarCsv() {
    const csv =
      '画像URL,ASIN,JAN,商品名,仕入値,売価,利益額,利益率,状態,SKU,注文日,配送経路,仕入店舗,登録者\n' +
      this.pricetars
        .map((p) => {
          return [
            p.imageUrl,
            p.asin,
            p.jan,
            p.productName,
            p.buyingPrice,
            p.sellingPrice,
            p.profit,
            p.profitRate,
            p.condition,
            p.sku,
            moment(p.orderDate).format('YYYY/MM/DD'),
            p.deliveryRoute,
            p.buyingStore,
            p.name,
          ].join(',');
        })
        .join('\n');

    this.outputCsv(csv, 'pricetar.csv');
  }

  outputSedoristCsv() {
    const csv =
      'ASIN,JAN,仕入値,在庫数,販売価格,購入店舗\n' +
      this.sedorists
        .map((p) => {
          return [p.asin, p.jan, p.buyingPrice, 1, p.sellingPrice, p.buyingStore].join(',');
        })
        .join('\n');

    this.outputCsv(csv, 'sedorist.csv');
  }

  outputAllSedoristCsv() {
    const csv =
      '画像URL,asin,JAN,商品名,仕入値,出品予定価格,利益額,利益率,状態,SKU,配送経路,仕入日,仕入店舗,登録者\n' +
      this.sedorists
        .map((p) => {
          return [
            p.imageUrl,
            p.asin,
            p.jan,
            p.productName,
            p.buyingPrice,
            p.sellingPrice,
            p.profit,
            p.profitRate,
            p.condition,
            p.sku,
            p.deliveryRoute,
            moment(p.buyingDate).format('YYYY/MM/DD'),
            p.buyingStore,
            p.name,
          ].join(',');
        })
        .join('\n');

    this.outputCsv(csv, 'sedorist.csv');
  }

  outputMergedCsv() {
    const csv =
      'jan,仕入値,在庫数,販売価格,購入店舗\n' +
      this.mergedList
        .map((p) => {
          return [p.jan, p.buyingPrice, 1, p.sellingPrice, p.buyingStore].join(',');
        })
        .join('\n');

    this.outputCsv(csv, 'pricetar_and_sedorist.csv');
  }

  outputAllMergedCsv() {
    const csv =
      '画像URL,asin,jan,商品名,仕入値,出品予定価格,利益額,利益率,SKU,配送経路,仕入日,仕入店舗,登録者\n' +
      this.mergedList
        .map((p) => {
          return [
            p.imageUrl,
            p.asin,
            p.jan,
            p.productName,
            p.buyingPrice,
            p.sellingPrice,
            p.profit,
            p.profitRate,
            p.sku,
            p.deliveryRoute,
            moment(p.buyingDate).format('YYYY/MM/DD'),
            p.buyingStore,
            p.name,
          ].join(',');
        })
        .join('\n');

    this.outputCsv(csv, 'pricetar_and_sedorist.csv');
  }

  private outputCsv(csv: string, filename: string) {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const elem = this.elementRef.nativeElement;
    const link = elem.querySelector('#csv-donwload') as HTMLAnchorElement;
    link.href = url;
    link.download = filename;
    link.click();
  }
}

interface ProductShareFilterParam {
  keyword: string;
  fromDate: Date;
  toDate: Date;
  sharedOnly: boolean;
  nonSharedOnly: boolean;
}

interface Pricetar {
  id: number;
  asin: string;
  jan: string;
  imageUrl: string;
  sku: string;
  email: string;
  name: string;
  productName: string;
  buyingPrice: number;
  sellingPrice: number;
  orderDate: Date;
  deliveryRoute: string;
  memo: string;
  ipMemo: string;
  isPriceDownInCacheRegister: boolean;
  isSleeped: boolean;
  buyingStore: string;
  salesCommission: number;
  profit: number;
  profitRate: number;
  condition: string;
  topCategory: string;
  isPublic: boolean;
  createdAt: Date;
}

interface Sedorist {
  id: number;
  asin: string;
  imageUrl: string;
  jan: string;
  sku: string;
  email: string;
  name: string;
  productName: string;
  buyingPrice: number;
  sellingPrice: number;
  profit: number;
  profitRate: number;
  buyingDate: Date;
  buyingStore: string;
  deliveryRoute: string;
  memo: string;
  ipMemo: string;
  isPriceDownInCacheRegister: boolean;
  isSleeped: boolean;
  isEditing: boolean;
  condition: string;
  topCategory: string;
  isPublic: boolean;
  createdAt: Date;
}

// sedorist / pricetarをマージしたobject
interface Merged {
  sedoristId: number;
  pricetarId: number;
  asin: string;
  imageUrl: string;
  jan: string;
  sku: string;
  email: string;
  name: string;
  productName: string;
  buyingPrice: number;
  sellingPrice: number;
  profit: number;
  profitRate: number;
  buyingDate: Date;
  buyingStore: string;
  condition: string;
  deliveryRoute: string;
  memo: string;
  ipMemo: string;
  isPriceDownInCacheRegister: boolean;
  isSleeped: boolean;
  topCategory: string;
  isPublic: boolean;
  serviceName: string; // sedorist or pricetar
  createdAt: Date;
}

interface BuyingStore {
  id: number;
  name: string;
}

interface BuyingStoreType {
  id: number;
  name: string;
  stores: Array<BuyingStore>;
}

interface AmazonCategory {
  id: number;
  name: string;
}
