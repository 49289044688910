import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessageService } from './message.service';
import { GraphQLFormattedError } from 'graphql';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public static readonly BASE_URL: string = environment.api.base_url + '/api';

  constructor(private http: HttpClient, private message: MessageService) { }

  hasExtensions(errors: ReadonlyArray<GraphQLFormattedError>): boolean {
    if (errors && errors[0].extensions) {
      return true;
    }
    return false;
  }

  get(path: string, version = 'v1'): Observable<object> {
    const url = HttpService.BASE_URL + '/' + version + path;
    return this.http.get(url);
  }

  post(path: string, body: any, version = 'v1'): Observable<object> {
    const url = HttpService.BASE_URL + '/' + version + path;
    return this.http.post(url, body);
  }

  put(path: string, body: any, version = 'v1'): Observable<object> {
    const url = HttpService.BASE_URL + '/' + version + path;
    return this.http.put(url, body);
  }

  delete(path: string, body: any, version = 'v1'): Observable<object> {
    const url = HttpService.BASE_URL + '/' + version + path;
    return this.http.delete(url, body);
  }

  handleError(err?: any) {
    this.message.setFlashMessage('error', 'エラー', '予期せぬエラーが発生しました。時間を置いて、再度お試しください。');
  }
}
